import React, { Component } from 'react';
import { Menu, Icon, Avatar, Row, Button } from 'antd';
import intl from 'react-intl-universal';
import { withRouter, Link } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';

import { AppContextConsumer } from 'context/AppContext';

import airbnbLogo from 'images/airbnb-logo.png';
import agodaLogo from 'images/agoda-logo-icon.png';
import expediaLogo from 'images/expedia-logo-icon.png';
import bookingcomLogo from 'images/booking-logo-icon.png';
import ctripLogo from 'images/ctrip-logo-icon.png';
import travelokaLogo from 'images/traveloka-logo-icon.png';
import tiketcomLogo from 'images/tiketcom-logo-icon.png';
import broomLogo from 'images/broom-logo-icon.png';

import { getConstants } from 'utils/apis/constants';
import { getHostStatus } from 'utils/apis/billingInvoice';
import {
  buildBillingUri,
  buildBillingInvoiceUri,
  buildBillingInventoryUri,
  buildListingUri,
  buildPayoutUri,
  buildReportingUri,
  buildWebhookUri,
  buildTaskManagementUri
} from 'utils/routes';

import { REACT_APP_IS_SHOW_TRAVELOKA, REACT_APP_IS_SHOW_INVENTORY, REACT_APP_IS_SHOW_TIKETCOM } from 'config/env';

import styles from './Navbar.module.css';
import { ReactComponent as WhatsAppIcon } from 'images/whatsapp-icon.svg';
import { acceptedUserList, SPECIAL_HOST_IDS } from 'utils/constants';

const { SubMenu } = Menu;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedMenuKey: '',
      permissions: [],
      permissionConstants: {},
      isAdmin: false,
      isOwner: false,
      isBanned: false,
      // Temp for TM
      userId: '',
      host: []
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.authorizedItem = this.authorizedItem.bind(this);
  }

  componentWillMount() {
    // console.log(this.props.location);

    getHostStatus()
      .then(res => {
        // console.log('host status', res);
        if (res.hostStatus === 'block') {
          this.setState({
            isBanned: true
          });

          if (window.location.pathname === '/billing/invoice') {
            // do nothing
          } else {
            window.location.assign('/billing/invoice');
          }
        }
      })
      .catch(err => {
        // console.log(err, 65);
      });

    getConstants('permissions').then(res => {
      const authObj = JSON.parse(localStorage.getItem('auth'));
      // console.log(authObj, 52);
      // console.log(res, 52);

      if (!authObj) {
        return;
      }

      let host = [];

      authObj.user.roles.forEach(usr => {
        host.push(usr.host);
      });

      if (res && res.status === 200) {
        this.setState({
          permissionConstants: res.data,
          permissions: authObj && authObj.user.roles.length > 0 ? authObj.user.roles[0].permissions : [],
          isAdmin: authObj.user.isAdmin,
          isOwner: authObj.user.isAdmin === false ? authObj.user.roles[0].name === 'Owner' : false,
          // Temp for TM
          userId: String(authObj.user._id),
          host: host
        });
      } else {
        console.log('Error getting permissions constants.');
      }
    });
  }

  handleOnClick = logout => e => {
    const { history } = this.props;
    this.setState({
      currentSelectedMenuKey: e.key
    });
    switch (e.key) {
      case 'overview':
        history.push('/overview');
        break;

      case 'bookingform':
        history.push('/bookingform');
        break;

      case 'multicalendar':
        history.push('/multicalendar');
        break;

      case 'reservation':
        history.push('/reservation');
        break;

      case 'ratemodifier':
        history.push('/ratemodifier');
        break;

      case 'host':
        history.push('/host');
        break;

      case 'billingInvoice':
        history.push(buildBillingInvoiceUri());
        break;

      case 'billingInventory':
        history.push(buildBillingInventoryUri());
        break;

      case 'users':
        history.push('/users');
        break;

      case 'listing':
        history.push(buildListingUri());
        break;

      case 'webhook':
        history.push(buildWebhookUri());
        break;

      case 'servicepackage':
        history.push('/servicepackage');
        break;

      case 'revenueshare':
        history.push('/revenueshare');
        break;

      case 'expenses':
        history.push('/expenses');
        break;

      case 'payout':
        history.push(buildPayoutUri());
        break;

      case 'integrate':
        history.push('/integrations/otaForm');
        break;

      case 'airbnb':
        history.push('/integrations/airbnb-connect');
        break;

      case 'agoda':
        history.push('/integrations/agoda-connect');
        break;

      case 'expedia':
        history.push('/integrations/expedia-connect');
        break;

      case 'bookingcom':
        history.push('/integrations/bookingcom-connect');
        break;

      case 'ctrip':
        history.push('/integrations/ctrip-connect');
        break;

      case 'traveloka':
        history.push('/integrations/traveloka-connect');
        break;
      case 'tiketcom':
        history.push('/integrations/tiketcom-connect');
        break;

      case 'activitylog':
        history.push('/activitylog');
        break;

      case 'access':
        history.push('/host-a-stay/access');
        break;

      case 'property':
        history.push('/host-a-stay/property');
        break;

      case 'taskList':
        history.push('/task-management/tasklist');
        break;

      case 'checkList':
        history.push('/task-management/checklist');
        break;

      case 'cleaningTeam':
        history.push('/task-management/cleaning-team');
        break;

      case 'presetTask':
        history.push('/task-management/preset-task');
        break;

      case 'logout':
        history.push('/logout');

        setTimeout(() => {
          window.location.reload();
        }, 200);
        break;

      default:
    }
  };

  getCurrentPathMenuKey = pathname => {
    switch (true) {
      case pathname.startsWith('/overview'):
        return 'overview';

      case pathname.startsWith('/bookingform'):
        return 'bookingform';

      case pathname.startsWith('/multicalendar'):
        return 'multicalendar';

      case pathname.startsWith('/reservation'):
        return 'reservation';

      case pathname.startsWith('/ratemodifier'):
        return 'ratemodifier';

      case pathname.startsWith(buildBillingUri()): {
        const reportingPathname = pathname.replace(buildBillingUri(), '');

        if (reportingPathname.startsWith(buildBillingInvoiceUri(false))) {
          return 'billingInvoice';
        } else if (reportingPathname.startsWith(buildBillingInventoryUri(false))) {
          return 'billingInventory';
        }

        return 'billing';
      }

      case pathname.startsWith('/users'):
        return 'users';

      case pathname.startsWith(buildListingUri()):
        return 'listing';

      case pathname.startsWith('/servicepackage'):
        return 'servicepackage';

      case pathname.startsWith('/revenueshare'):
        return 'revenueshare';

      case pathname.startsWith('/expenses'):
        return 'expenses';

      case pathname.startsWith(buildPayoutUri()):
        return 'payout';

      case pathname.startsWith(buildReportingUri()):
        return 'reporting';

      case pathname.startsWith(buildWebhookUri()):
        return 'webhook';

      case pathname.startsWith('/integrations'): {
        const integrationPathname = pathname.replace('/integrations', '');
        if (integrationPathname.startsWith('/airbnb')) {
          return 'airbnb';
        }
        if (integrationPathname.startsWith('/agoda')) {
          return 'agoda';
        }
        if (integrationPathname.startsWith('/bookingcom')) {
          return 'bookingcom';
        }
        if (integrationPathname.startsWith('/expedia')) {
          return 'expedia';
        }
        if (integrationPathname.startsWith('/ctrip')) {
          return 'ctrip';
        }
        if (integrationPathname.startsWith('/traveloka')) {
          return 'traveloka';
        }
        if (integrationPathname.startsWith('/tiketcom')) {
          return 'tiketcom';
        }
        return 'integrate';
      }

      case pathname.startsWith('/host-a-stay'): {
        const integrationPathname = pathname.replace('/host-a-stay', '');
        if (integrationPathname.startsWith('/access')) {
          return 'access';
        }

        if (integrationPathname.startsWith('/property')) {
          return 'property';
        }

        return 'hostStay';
      }

      case pathname.startsWith('/host'):
        return 'host';

      case pathname.startsWith('/activitylog'):
        return 'activitylog';

      case pathname.startsWith('/task-management'):
        const integrationPathname = pathname.replace('/task-management', '');
        if (integrationPathname.startsWith('/tasklist')) {
          return 'taskList';
        }
        if (integrationPathname.startsWith('/checklist')) {
          return 'checkList';
        }
        if (integrationPathname.startsWith('/cleaning-team')) {
          return 'cleaningTeam';
        }
        if (integrationPathname.startsWith('/preset-task')) {
          return 'presetTask';
        }
        return 'taskManagement';

      default:
        return '';
    }
  };

  getCurrentPathOpenKey = pathname => {
    switch (true) {
      case pathname.startsWith(buildBillingUri()):
        return 'billing';

      case pathname.startsWith('/integrations'):
        return 'integrations';

      case pathname.startsWith('/host-a-stay'):
        return 'hostStay';

      case pathname.startsWith(buildPayoutUri()):
        return 'payouts';

      case '/host-a-stay/access':
        return 'hostStay';

      case pathname.startsWith('/task-management'):
        return 'taskManagement';

      default:
        return '';
    }
  };

  authorizedItem = (keys, Component) => {
    const { isAdmin, permissions } = this.state;
    if (isAdmin) {
      return Component;
    }

    return keys.filter(key => permissions.includes(key)).length > 0 ? Component : null;
  };

  render() {
    const { location, checkIsAdminReadOnly } = this.props;
    const { currentSelectedMenuKey, permissionConstants, isOwner, isBanned, host } = this.state;
    const currentKey = this.getCurrentPathMenuKey(location.pathname);
    const currentOpenKey = this.getCurrentPathOpenKey(location.pathname);
    return (
      <AppContextConsumer>
        {({ logout }) =>
          isBanned ? (
            <Menu
              mode="inline"
              defaultSelectedKeys={[currentKey]}
              defaultOpenKeys={[currentOpenKey]} // Higher lvel
              selectedKeys={[currentKey || currentSelectedMenuKey]}
              style={{ height: '100%' }}
              onClick={this.handleOnClick(logout)}
            >
              <SubMenu
                key="billing"
                title={
                  <span>
                    <Icon type="transaction" />
                    <span>{intl.get('navbar.labels.billing').d('Billing')}</span>
                  </span>
                }
              >
                <Menu.Item key="billingInvoice">
                  <Icon type="account-book" />
                  <Link className={styles.navbarMenu} to={buildBillingInvoiceUri()}>
                    <span>{intl.get('navbar.labels.billingInvoice').d('Invoice')}</span>
                  </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="logout">
                <Icon type="logout" />
                <span>{intl.get('navbar.labels.logout').d('Logout')}</span>
              </Menu.Item>
              {!isOwner && (
                <Row style={{ padding: '16px' }}>
                  <a href={`https://api.whatsapp.com/send?phone=60162537829`} target="_blank" rel="noopener noreferrer">
                    <Button type="primary" className="whatsapp-button">
                      <Icon component={WhatsAppIcon} /> Customer Support
                    </Button>
                  </a>
                </Row>
              )}
              <Row style={{ paddingLeft: '24px', paddingTop: '12px' }}>
                <p style={{ marginBottom: '24px', fontSize: '14px', color: 'grey' }}>{'Sales Contact: '}</p>
                <p style={{ marginBottom: '36px', fontSize: '14px', color: 'grey' }}>{'+010-829 7829'}</p>
                {!isOwner && <p style={{ marginBottom: '24px', fontSize: '14px', color: 'grey' }}>{'Finance Contact: '}</p>}
                {!isOwner && <p style={{ fontSize: '14px', color: 'grey' }}>{'+016-252 7829'}</p>}
              </Row>
            </Menu>
          ) : (
            <Menu
              mode="inline"
              defaultSelectedKeys={[currentKey]}
              defaultOpenKeys={[currentOpenKey]} // Higher lvel
              selectedKeys={[currentKey || currentSelectedMenuKey]}
              style={{ height: '100%' }}
              onClick={this.handleOnClick(logout)}
            >
              {this.authorizedItem(
                [permissionConstants.RESERVATIONS_VIEW],
                <Menu.Item key="overview">
                  <Icon type="home" />
                  <Link className={styles.navbarMenu} to="/overview">
                    <span>{intl.get('navbar.labels.overview').d('Overview')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.RESERVATIONS_CREATE],
                <Menu.Item key="bookingform">
                  <Icon type="layout" />
                  <Link className={styles.navbarMenu} to="/bookingform">
                    <span>{intl.get('navbar.labels.bookingForm').d('Booking Form')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.RESERVATIONS_VIEW],
                <Menu.Item key="multicalendar">
                  <Icon type="calendar" />
                  <Link className={styles.navbarMenu} to="/multicalendar">
                    <span>{intl.get('navbar.labels.multiCalendar').d('Multi Calendar')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.RESERVATIONS_VIEW],
                <Menu.Item key="reservation">
                  <Icon type="profile" />
                  <Link className={styles.navbarMenu} to="/reservation">
                    <span>{intl.get('navbar.labels.reservation').d('Reservation')}</span>
                  </Link>
                </Menu.Item>
              )}

              {// (acceptedUserList.filter(usr => {
              //   return String(usr) === String(this.state.userId);
              // }).length > 0 ||
              //   acceptedUserList.filter(usr => {
              //     return this.state.host.filter(host => String(host) === String(usr)).length > 0;
              //   }).length > 0) &&
              this.authorizedItem(
                [permissionConstants.TASK_MANAGEMENT_VIEW],
                <SubMenu
                  key="taskManagement"
                  title={
                    <div>
                      <Avatar size={14} src={broomLogo} style={{ marginRight: 8 }} />
                      <span>{intl.get('navbar.labels.taskManagement').d('Task Management')}</span>
                    </div>
                  }
                >
                  {this.authorizedItem(
                    [permissionConstants.TASK_MANAGEMENT_VIEW],
                    <Menu.Item key="taskList">
                      <Icon type="ordered-list" />
                      <Link className={styles.navbarMenu} to={'/task-management/tasklist'}>
                        <span>{intl.get('navbar.labels.taskList').d('Task List')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [
                      permissionConstants.TASK_MANAGEMENT_CREATE,
                      permissionConstants.TASK_MANAGEMENT_MODIFY,
                      permissionConstants.TASK_MANAGEMENT_DELETE
                    ],
                    <Menu.Item key="checkList">
                      <Icon type="check-square" />
                      <Link className={styles.navbarMenu} to={`${buildTaskManagementUri()}/checklist`}>
                        <span>{intl.get('navbar.labels.checkList').d('Checklist')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [
                      permissionConstants.TASK_MANAGEMENT_CREATE,
                      permissionConstants.TASK_MANAGEMENT_MODIFY,
                      permissionConstants.TASK_MANAGEMENT_DELETE
                    ],
                    <Menu.Item key="cleaningTeam">
                      <Icon type="team" />
                      <Link className={styles.navbarMenu} to={`${buildTaskManagementUri()}/cleaning-team`}>
                        <span>{intl.get('navbar.labels.cleaningTeam').d('Cleaning Team')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [
                      permissionConstants.TASK_MANAGEMENT_CREATE,
                      permissionConstants.TASK_MANAGEMENT_MODIFY,
                      permissionConstants.TASK_MANAGEMENT_DELETE
                    ],
                    <Menu.Item key="presetTask">
                      <Icon type="setting" />
                      <Link className={styles.navbarMenu} to={`${buildTaskManagementUri()}/preset-task`}>
                        <span>{intl.get('navbar.labels.presetTask').d('Preset Task')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {this.authorizedItem(
                [permissionConstants.RATE_MODIFIER_VIEW],
                <Menu.Item key="ratemodifier">
                  <Icon type="diff" />
                  <Link className={styles.navbarMenu} to="/ratemodifier">
                    <span>{intl.get('navbar.labels.ratemodifier').d('Rate Modifier')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.HOST_VIEW],
                <Menu.Item key="host">
                  <Icon type="team" />
                  <Link className={styles.navbarMenu} to="/host">
                    <span>{intl.get('navbar.labels.host').d('Host')}</span>
                  </Link>
                </Menu.Item>
              )}

              {this.authorizedItem(
                [permissionConstants.BILLING_VIEW],
                <SubMenu
                  key="billing"
                  title={
                    <span>
                      <Icon type="transaction" />
                      <span>{intl.get('navbar.labels.billing').d('Billing')}</span>
                    </span>
                  }
                >
                  {this.authorizedItem(
                    [permissionConstants.BILLING_VIEW],
                    <Menu.Item key="billingInvoice">
                      <Icon type="account-book" />
                      <Link className={styles.navbarMenu} to={buildBillingInvoiceUri()}>
                        <span>{intl.get('navbar.labels.billingInvoice').d('Invoice')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {REACT_APP_IS_SHOW_INVENTORY &&
                    this.authorizedItem(
                      [permissionConstants.REPORTS_VIEW],
                      <Menu.Item key="billingInventory">
                        <Icon type="profile" />
                        <Link className={styles.navbarMenu} to={buildBillingInventoryUri()}>
                          <span>{intl.get('navbar.labels.inventory').d('Inventory')}</span>
                        </Link>
                      </Menu.Item>
                    )}
                </SubMenu>
              )}
              {this.authorizedItem(
                [permissionConstants.USERS_VIEW],
                <Menu.Item key="users">
                  <Icon type="user" />
                  <Link className={styles.navbarMenu} to="/users">
                    <span>{intl.get('navbar.labels.users').d('Users')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.LISTINGS_VIEW, permissionConstants.RENTAL_VIEW],
                <Menu.Item key="listing">
                  <Icon type="key" />
                  <Link className={styles.navbarMenu} to="/listing">
                    <span>{intl.get('navbar.labels.listing').d('Listing')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.SERVICE_PACKAGE_VIEW],
                <Menu.Item key="servicepackage">
                  <Icon type="share-alt" />
                  <Link className={styles.navbarMenu} to="/servicepackage">
                    <span>{intl.get('navbar.labels.servicePackage').d('Service Package')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.REVENUE_SHARE_VIEW],
                <Menu.Item key="revenueshare">
                  <Icon type="cluster" />
                  <Link className={styles.navbarMenu} to="/revenueshare">
                    <span>{intl.get('navbar.labels.revenueShare').d('Revenue Share')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.EXPENSES_VIEW],
                <Menu.Item key="expenses">
                  <Icon type="shopping-cart" />
                  <Link className={styles.navbarMenu} to="/expenses">
                    <span>{intl.get('navbar.labels.expenses').d('Expenses')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.PAYOUTS_VIEW],
                <Menu.Item key="payout">
                  <Icon type="pay-circle-o" />
                  <Link className={styles.navbarMenu} to={buildPayoutUri()}>
                    <span>{intl.get('navbar.labels.payout').d('Payout')}</span>
                  </Link>
                </Menu.Item>
              )}
              {this.authorizedItem(
                [permissionConstants.REPORTS_VIEW],
                <Menu.Item key="reporting">
                  <Icon type="area-chart" />
                  <Link className={styles.navbarMenu} to={buildReportingUri()}>
                    <span>{intl.get('navbar.labels.reporting').d('Reporting')}</span>
                  </Link>
                </Menu.Item>
              )}

              {this.authorizedItem(
                [permissionConstants.WEBHOOK_VIEW],
                <Menu.Item key="webhook">
                  <Icon type="cloud" />
                  <Link className={styles.navbarMenu} to={buildWebhookUri()}>
                    <span>Webhook</span>
                  </Link>
                </Menu.Item>
              )}

              {this.authorizedItem(
                [permissionConstants.TORUS_VIEW],
                <SubMenu
                  key="hostStay"
                  title={
                    <span>
                      <Icon type="home" />
                      <span>Host a Stay</span>
                    </span>
                  }
                >
                  <Menu.Item key="access">
                    <Icon type="sliders" />
                    <Link className={styles.navbarMenu} to={'/host-a-stay/access'}>
                      <span>Access</span>
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="property">
                    <Icon type="project" />
                    <Link className={styles.navbarMenu} to={'/host-a-stay/access'}>
                      <span>Property</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {this.authorizedItem(
                [permissionConstants.INTEGRATIONS_VIEW],
                <SubMenu
                  key="integrations"
                  title={
                    <span>
                      <Icon type="api" />
                      <span>{intl.get('navbar.labels.integrations').d('HostConnect')}</span>
                    </span>
                  }
                >
                  {this.authorizedItem(
                    [permissionConstants.INTEGRATIONS_VIEW],
                    <Menu.Item key="integrate">
                      <Icon type="fork" className={styles.integrateIcon} />
                      <Link className={styles.navbarMenu} to="/integrations">
                        <span>{intl.get('navbar.labels.integrationListing').d('Integrations')}</span>
                      </Link>
                    </Menu.Item>
                  )}

                  {this.authorizedItem(
                    [permissionConstants.INTEGRATIONS_VIEW],
                    <Menu.Item key="airbnb">
                      <Avatar size="small" src={airbnbLogo} />
                      <Link className={styles.intergrationMenuBar} to="/integrations/airbnb-connect">
                        <span>{intl.get('navbar.labels.airbnb').d('Airbnb')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [permissionConstants.INTEGRATIONS_VIEW],
                    <Menu.Item key="agoda">
                      <Avatar size="small" src={agodaLogo} />
                      <Link className={styles.intergrationMenuBar} to="/integrations/agoda-connect">
                        <span>{intl.get('navbar.labels.agoda').d('Agoda')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [permissionConstants.INTEGRATIONS_VIEW],
                    <Menu.Item key="expedia">
                      <Avatar size="small" src={expediaLogo} />
                      <Link className={styles.intergrationMenuBar} to="/integrations/expedia-connect">
                        <span>{intl.get('navbar.labels.expedia').d('Expedia')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [permissionConstants.INTEGRATIONS_VIEW],
                    <Menu.Item key="bookingcom">
                      <Avatar size="small" src={bookingcomLogo} />
                      <Link className={styles.intergrationMenuBar} to="/integrations/bookingcom-connect">
                        <span>{intl.get('navbar.labels.bookingcom').d('Booking.com')}</span>
                      </Link>
                    </Menu.Item>
                  )}
                  {this.authorizedItem(
                    [permissionConstants.INTEGRATIONS_VIEW],
                    <Menu.Item key="ctrip">
                      <Avatar size="small" src={ctripLogo} />
                      <Link className={styles.intergrationMenuBar} to="/integrations/ctrip-connect">
                        <span>{intl.get('navbar.labels.ctrip').d('Ctrip')}</span>
                      </Link>
                    </Menu.Item>
                  )}

                  {REACT_APP_IS_SHOW_TRAVELOKA &&
                    this.authorizedItem(
                      [permissionConstants.INTEGRATIONS_VIEW],
                      <Menu.Item key="traveloka">
                        <Avatar size="small" src={travelokaLogo} />
                        <Link className={styles.intergrationMenuBar} to="/integrations/traveloka-connect">
                          <span>{intl.get('navbar.labels.traveloka').d('Traveloka')}</span>
                        </Link>
                      </Menu.Item>
                    )}

                  {REACT_APP_IS_SHOW_TIKETCOM &&
                    this.authorizedItem(
                      [permissionConstants.INTEGRATIONS_VIEW],
                      <Menu.Item key="tiketcom">
                        <Avatar size="small" src={tiketcomLogo} />
                        <Link className={styles.intergrationMenuBar} to="/integrations/tiketcom-connect">
                          <span>{intl.get('navbar.labels.tiketcom').d('Tiket.com')}</span>
                        </Link>
                      </Menu.Item>
                    )}
                </SubMenu>
              )}
              {/*this.authorizedItem(
  [permissionConstants.ACTIVITY_LOG_VIEW],
  <Menu.Item key="activitylog">
    <Icon type="ordered-list" />
    <span>{intl.get('navbar.labels.activitylog').d('Activity Log')}</span>
  </Menu.Item>
)*/}
              <Menu.Item key="logout">
                <Icon type="logout" />
                <span>{intl.get('navbar.labels.logout').d('Logout')}</span>
              </Menu.Item>

              {host.length > 0 && SPECIAL_HOST_IDS.includes(host[0]) !== true && (
                <>
                  {!isOwner && (
                    <Row style={{ padding: '16px' }}>
                      <a href={`https://api.whatsapp.com/send?phone=60162537829`} target="_blank" rel="noopener noreferrer">
                        <Button type="primary" className="whatsapp-button">
                          <Icon component={WhatsAppIcon} /> {intl.get('navbar.labels.customerSupport').d('Customer Support')}
                        </Button>
                      </a>
                    </Row>
                  )}
                  <Row style={{ paddingLeft: '24px', paddingTop: '12px' }}>
                    <p style={{ marginBottom: '24px', fontSize: '14px', color: 'grey' }}>
                      {intl.get('navbar.labels.salesContact').d('Sales Contact')}
                    </p>
                    <p style={{ marginBottom: '36px', fontSize: '14px', color: 'grey' }}>{'+010-829 7829'}</p>
                    {!isOwner && (
                      <p style={{ marginBottom: '24px', fontSize: '14px', color: 'grey' }}>
                        {intl.get('navbar.labels.financeContact').d('Finance Contact')}
                      </p>
                    )}
                    {!isOwner && <p style={{ fontSize: '14px', color: 'grey' }}>{'+016-252 7829'}</p>}
                  </Row>
                </>
              )}
            </Menu>
          )
        }
      </AppContextConsumer>
    );
  }
}

export default withAppContext(withRouter(Navbar));
