import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch, Form, Card, Col, Row, Tooltip, Icon, message, Select } from 'antd';

import { getConstants } from 'utils/apis/constants';
import { generatePercentageFromDisplay } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';
import RateOptionModal from './component/RateOptionModal';

import styles from './SyncCard.module.css';
import intl from 'react-intl-universal';

const FormItem = Form.Item;

const contstructFormInput = (form, inputName, value, disabled = false) => (
  <FormInput className={styles.formInput} name={inputName} form={form} defaultValue={value} size="large" disabled={disabled} />
);

const SPECIAL_HOST_IDS = ['5e2692104be7845599b3d2fe', '67b834191b208904d0b94070', '67a0205060c37704799b183d'];
const sellingModeOptions = [{ label: 'Normal', value: 'normal' }, { label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }];
class SyncCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSetDefaultValue: false,
      roomTypes: [],
      isLoading: true,
      isRateModalVisible: false,
      rateModifierTypeConstants: [],
      rateModifierSignConstants: [],
      currency: 'MYR'
    };
  }

  componentDidMount() {
    const { defaultValues, form } = this.props;
    // const roomTypes = form.getFieldValue('roomTypes') || [];

    Promise.all([this.loadConstants()]).then(() => {
      this.setState({
        roomTypes: defaultValues.roomTypes,
        propSync: defaultValues.propSync,
        isLoading: false
      });
    });
  }

  loadConstants = async () => {
    const rateModifierTypeConstants = await getConstants('rateModifierType').then(res => Object.values(res.data));
    const rateModifierSignConstants = await getConstants('rateModifierSign').then(res => Object.values(res.data));

    this.setState({
      rateModifierTypeConstants,
      rateModifierSignConstants
    });
  };

  static getDerivedStateFromProps(props, state) {
    // Set roomTypes state with data
    const { defaultValues, form } = props;
    const { isSetDefaultValue } = state;
    const roomTypes = form.getFieldValue('roomTypes') || [];
    if (!isSetDefaultValue && roomTypes.length === 0 && defaultValues.roomTypes && defaultValues.roomTypes.length > 0 && defaultValues.propSync) {
      props.form.setFieldsValue({
        roomTypes: defaultValues.roomTypes,
        propSync: defaultValues.propSync
      });
      return {
        isSetDefaultValue: true
      };
    }
    return null;
  }

  handleOnSync = (index, name) => checked => {
    const { form } = this.props;
    let roomTypes = this.state.roomTypes;
    roomTypes[index].sync = checked;

    roomTypes[index] = {
      ...roomTypes[index],
      sync: checked
    };
    form.setFieldsValue({
      sync: checked
    });
    this.setState({
      roomTypes
    });
  };

  handleOnPropSync = () => checked => {
    const { form } = this.props;

    form.setFieldsValue({
      propSync: checked
    });
    this.setState({
      propSync: checked
    });
  };

  handleOnAdvanceOptionsClick = isVisible => e => {
    e.preventDefault();

    this.setState({
      isRateModalVisible: isVisible
    });
  };

  handleOnAdvanceOptionsSave = payload => {
    const { onSaved } = this.props;
    const { roomTypes, rateModifierTypeConstants, rateModifierSignConstants } = this.state;

    const processedRoomMapping = roomTypes.map(room => {
      const rateDistribution = payload.rateDistributionPayload.find(rateDistribution => room.key === rateDistribution.roomId);

      const rateObject = rateDistribution.rates.find(rateObject => String(rateObject.otaId) === '');

      if (!!rateObject) {
        const isDerived = rateObject.rate.isDerived;
        let processedRoomRateObject = {
          rate: {
            weekday: rateObject.rate.weekdayRate,
            weekend: rateObject.rate.weekendRate,
            isDerived,
            roomId: rateDistribution.roomId
          }
        };

        if (isDerived) {
          const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
          const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
          let amount = rateObject.rate.modifierAmount;
          amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

          processedRoomRateObject.rate.calculation = {
            type: rateObject.rate.modifierType,
            isPositive,
            amount,
            parent: room.rate.stdRateId ? room.rate.stdRateId : room.rate._id
          };
        }

        return processedRoomRateObject;
      }

      return { ...room };
    });

    const updatedRoomTypes = roomTypes.map(room => {
      return {
        ...room,
        rate: {
          ...processedRoomMapping.find(rate => rate.rate.roomId === room.key).rate,
          code: 'HBE',
          stdRateId: room.rate.stdRateId,
          _id: room.rate._id
        }
      };
    });
    onSaved(processedRoomMapping);

    this.setState({
      isRateModalVisible: false,
      roomTypes: updatedRoomTypes
    });
  };

  render() {
    const { form, defaultValues, cardClassname, host } = this.props;
    const { isLoading, roomTypes, propSync, isRateModalVisible, rateModifierTypeConstants, rateModifierSignConstants, currency } = this.state;
    const columns = [
      {
        title: intl.get('listings.bookingEngine.tableColumns.sync').d('Sync'),
        dataIndex: 'sync',
        render: (value, row, index) => {
          return (
            <FormItem>
              {form.getFieldDecorator(`${row.key}.sync`, {
                initialValue: defaultValues.roomTypes[index].sync
              })(<Switch checked={roomTypes[index].sync} onChange={this.handleOnSync(index, `${row.key}.sync`)} disabled={!propSync} />)}
            </FormItem>
          );
        }
      },
      {
        title: intl.get('listings.bookingEngine.tableColumns.roomType').d('Room Type'),
        dataIndex: 'name',
        render: (value, row) => {
          return contstructFormInput(form, `${row.key}.name`, value, true);
        }
      },
      {
        title: intl.get('listings.bookingEngine.tableColumns.external').d('External Display Name'),
        dataIndex: 'externalDisplayName',
        render: (value, row) => {
          const syncStatus = form.getFieldValue(`${row.key}.sync`);
          return contstructFormInput(form, `${row.key}.externalDisplayName`, value, !syncStatus || !propSync);
        }
      },
      ...(SPECIAL_HOST_IDS.includes(host)
        ? [
            {
              title: intl.get('listings.bookingEngine.tableColumns.sellingMode').d('Selling Mode'),
              dataIndex: 'sellingMode',
              render: (value, row) => {
                const syncStatus = form.getFieldValue(`${row.key}.sync`);
                return (
                  <FormItem>
                    {form.getFieldDecorator(`${row.key}.sellingMode`, {
                      initialValue: value || 'normal'
                    })(
                      <Select disabled={!syncStatus || !propSync} style={{ width: '100%' }}>
                        {sellingModeOptions.map(option => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                );
              }
            }
          ]
        : []),
      {
        title: intl.get('hostConnect.integration.headerLabels.rateDistribution').d('Rate Distribution'),
        dataIndex: 'rate',
        render: (value, row) => {
          if (value.code === 'HBE') {
            if (value.isDerived) {
              return <span>{intl.get('hostConnect.integration.rateDerivative.Derived from Web').d('Derived from Web')}</span>;
            } else {
              return <span>{intl.get('hostConnect.integration.rateDerivative.Independent').d('Independent')}</span>;
            }
          }
          return (
            <div>
              <span>{intl.get('listings.bookingEngine.message.bookingWebRateNotSet').d('Booking Website Rate not set.')}</span>
              <Tooltip
                title={intl
                  .get('listings.bookingEngine.message.bookingWebRateNotSetContent')
                  .d('If Booking Website Rate is not set, rate will be based on Standard Web Rate.')}
              >
                <Icon style={{ marginLeft: 5 }} type="question-circle-o" />
              </Tooltip>
            </div>
          );
        }
      }
    ];

    return (
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card
            className={cardClassname}
            title={intl.get('listings.bookingEngine.headerLabels.propertySync').d('Property Sync')}
            loading={isLoading}
            extra={form.getFieldDecorator(`propSync`, {
              initialValue: defaultValues.propSync
            })(
              <Row>
                <Switch checked={propSync} onChange={this.handleOnPropSync()} />
                <Tooltip title={intl.get('listings.bookingEngine.headerLabels.toggle').d('Toggle to start/stop selling in Booking Website')}>
                  <Icon style={{ marginLeft: 20 }} type="question-circle-o" />
                </Tooltip>
              </Row>
            )}
          >
            {isRateModalVisible && (
              <RateOptionModal
                roomMapping={roomTypes}
                rateModifierTypeConstants={rateModifierTypeConstants}
                rateModifierSignConstants={rateModifierSignConstants}
                isVisible={isRateModalVisible}
                onConfirm={this.handleOnAdvanceOptionsSave}
                onClose={this.handleOnAdvanceOptionsClick(false)}
                currency={currency}
              />
            )}
            <Table columns={columns} dataSource={roomTypes} scroll={{ x: 700 }} bordered pagination={false} />
            <Row>
              <br />
              <a href=" " onClick={this.handleOnAdvanceOptionsClick(true)} style={{ marginBottom: 30 }}>
                {intl.get('listings.bookingEngine.headerLabels.rateSettings').d('Rate Settings')}
              </a>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

SyncCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string,
  defaultValues: PropTypes.object,
  hasFetchedRoomType: PropTypes.bool,
  onSaved: PropTypes.func,
  host: PropTypes.string
};

SyncCard.defaultProps = {
  defaultValues: {},
  form: {},
  cardClassname: '',
  hasFetchedRoomType: false,
  onSaved: () => {},
  host: ''
};

export default SyncCard;
