import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Modal, Row, Col, Alert, Upload, message, Table, Tooltip, Tag, Button } from 'antd';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { validateBulk, uploadBulk } from 'utils/apis/expenses';
import intl from 'react-intl-universal';

const BulkExpensesUploadModal = props => {
  // // Your array of objects
  // const dataArray = [
  //   {
  //     booked: '2023-04-30T00:06:00-07:00',
  //     bookingAmount: 909.56,
  //     checkIn: '45142',
  //     checkOut: '45144',
  //     confirmation: '1500634960',
  //     guest: 'YEONGMUN YOON',
  //     haveError: [],
  //     paymentType: 'Expedia Collect',
  //     reservationId: '1500634960',
  //     room: 'Family Penthouse',
  //     status: 'postStay'
  //   },
  //   {
  //     booked: '2023-04-30T00:06:00-07:00',
  //     bookingAmount: 909.56,
  //     checkIn: '45142',
  //     checkOut: '45144',
  //     confirmation: '1500634960',
  //     guest: 'YEONGMUN YOON',
  //     haveError: [],
  //     paymentType: 'Expedia Collect',
  //     reservationId: '1500634960',
  //     room: 'Family Penthouse',
  //     status: 'postStay'
  //   }
  //   // ... (other objects in the array)
  // ];

  // // Use map to create a new array with haveError removed
  // const newArray = dataArray.map(obj => {
  //   // Create a copy of the object without the haveError property
  //   const newObj = { ...obj };
  //   delete newObj.haveError;
  //   return newObj;
  // });

  // console.log('newArray', newArray);

  const [loading, setLoading] = useState(false);
  const [validatedData, setValidatedData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);

  const handleUploadedTemplate = async value => {
    /* 
Flow
1. Check is file valid
2. Parse excel file
3. Check parsed file format
4. Compose data
5. Check value
 */
    // console.log(65, value.file);
    setUploadedFile([value.file]);

    var output = await parseExcel(value.file);

    const isSchemaValid = await validateFormat(output);
    if (isSchemaValid.error === true) {
      message.error(isSchemaValid.message);
      setUploadedFile([]);
      return;
    }

    var newData = [];
    for (let i = 0; i < output.rows.length; i++) {
      var item = output.rows[i];
      let errorList = [];
      // // Check email duplicate data
      // const idDuplicate = output.rows.filter(x => x.bookingId === item.bookingId);
      // if (idDuplicate.length > 1) {
      //   errorList.push({
      //     error: 'Duplicate',
      //     column: 'Booking ID'
      //   });
      // }
      // // Check username duplicate
      // const nricDuplicate = output.rows.filter(x => x.nric === item.nric);
      // if (nricDuplicate.length > 1) {
      //   errorList.push({
      //     error: 'Duplicate',
      //     column: 'NRIC'
      //   });
      // }

      errorList = [...errorList, ...output.errors.filter(err => err.row === i + 2)];

      newData.push({
        ...item,
        haveError: errorList
      });
    }
    // console.log(59, newData, output);
    setValidatedData(newData);

    setLoading(true);
    const apiValidatedData = await validateBulk(newData);
    // console.log('apiValidatedData', apiValidatedData);

    if (apiValidatedData.status === 200) {
      setValidatedData(newData);
    }

    setLoading(false);
  };

  const validateFormat = output => {
    // console.log(120, output);
    if (output.rows.length === 0) {
      setUploadedFile([]);
      return {
        error: true,
        message: 'Invalid schema'
      };
    }
    if (output.errors.length > 0) {
      message.error('Contain error please fix and try again');
      return {
        error: false,
        message: 'Contain error please fix and try again'
      };
    }
    return {
      error: false
    };
  };

  const parseExcel = file => {
    return new Promise((resolve, reject) => {
      readXlsxFile(file, {
        schema: {
          svcPacName: {
            prop: 'svcPacName',
            type: String,
            required: true
          },
          unitId: {
            prop: 'unitId',
            type: String,
            required: true
          },
          unitNo: {
            prop: 'unitNo',
            type: String,
            required: true
          },
          electricity: {
            prop: 'electricity',
            type: Number,
            required: true
          },
          water: {
            prop: 'water',
            type: Number,
            required: true
          },
          internet: {
            prop: 'internet',
            type: Number,
            required: true
          },
          cleaning: {
            prop: 'cleaning',
            type: Number,
            required: true
          },
          laundry: {
            prop: 'laundry',
            type: Number,
            required: true
          },
          checkIn: {
            prop: 'checkIn',
            type: Number,
            required: true
          },
          checkOut: {
            prop: 'checkOut',
            type: Number,
            required: true
          },
          service: {
            prop: 'service',
            type: Number,
            required: true
          }
        }
      })
        .then(output => {
          resolve(output);
        })
        .catch(err => {
          message.error('Invalid Excel File');
          reject(err);
        });
    });
  };

  const resetUpload = () => {
    setUploadedFile([]);
    setValidatedData([]);
  };

  const handleUpload = async () => {
    setLoading(true);
    const res = await uploadBulk({
      validatedData: validatedData,
      year: props.year,
      month: props.month,
      servicePackages: props.servicePackages
    });

    if (res && res.status === 200) {
      Modal.success({
        title: res.data.message,
        onOk: () => {
          window.location.reload();
        }
      });
    } else {
      Modal.error({
        title: 'Bulk upload failed',
        onOk: () => {
          window.location.reload();
        }
      });
    }

    props.onCancel();
    setLoading(false);
  };

  const columns = [
    {
      title: 'Status',
      sorter: (a, b) => a.haveError.length - b.haveError.length,
      render: index => {
        if (index.haveError.length === 0) {
          return <Tag color="green">Accepted</Tag>;
        } else {
          return (
            <Tooltip
              title={
                <Row>
                  <Col span={24} style={{ fontWeight: 600 }}>
                    Error List :
                  </Col>
                  {index.haveError.map((value, index) => (
                    <Col key={index} span={24}>
                      {index + 1}. {value.column} {value.error}
                    </Col>
                  ))}
                </Row>
              }
            >
              <Tag color="red">
                <QuestionCircleOutlined /> Error
              </Tag>
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Service Package Name',
      dataIndex: 'svcPacName',
      key: 'svcPacName'
    },
    {
      title: 'Unit Id',
      dataIndex: 'unitId',
      key: 'unitId'
    },
    {
      title: 'Unit Name',
      dataIndex: 'unitNo',
      key: 'unitNo'
    },
    {
      title: 'Electricity',
      dataIndex: 'electricity',
      key: 'electricity'
    },
    {
      title: 'Water',
      dataIndex: 'water',
      key: 'water'
    },
    {
      title: 'Internet',
      dataIndex: 'internet',
      key: 'internet'
    },
    {
      title: 'Cleaning',
      dataIndex: 'cleaning',
      key: 'cleaning'
    },
    {
      title: 'Laundry',
      dataIndex: 'laundry',
      key: 'laundry'
    },
    {
      title: 'Check-In',
      dataIndex: 'checkIn',
      key: 'checkIn'
    },
    {
      title: 'Check-Out',
      dataIndex: 'checkOut',
      key: 'checkOut'
    },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service'
    }
  ];

  return (
    <Modal
      title="Bulk Expenses Update"
      visible={props.visible}
      onOk={handleUpload}
      onCancel={() => {
        resetUpload();
        props.onCancel();
      }}
      width={'75%'}
      okText="Upload"
      okButtonProps={{
        disabled: validatedData.length === 0 || validatedData.filter(x => x.haveError.length > 0).length > 0,
        loading: loading
      }}
      cancelText={'Close'}
    >
      <Alert
        description={
          <div>
            <p>
              Download template record: {/* <CSVLink filename={props.csvName} data={props.csvTemplate} className="exp-csv"> */}
              <Button
                id="csv-button3-host"
                type="primary"
                icon="download"
                onClick={async () => {
                  // console.log('props.csvTemplate', props.csvTemplate);
                  await writeXlsxFile(props.csvTemplate, {
                    schema: [
                      {
                        column: 'svcPacName',
                        type: String,
                        value: data => data.svcPacName
                      },
                      {
                        column: 'unitId',
                        type: String,
                        value: data => data.unitId
                      },
                      {
                        column: 'unitNo',
                        type: String,
                        value: data => data.unitNo
                      },
                      {
                        column: 'electricity',
                        type: Number,
                        value: data => data.electricity
                      },
                      {
                        column: 'water',
                        type: Number,
                        value: data => data.water
                      },
                      {
                        column: 'internet',
                        type: Number,
                        value: data => data.internet
                      },
                      {
                        column: 'cleaning',
                        type: Number,
                        value: data => data.cleaning
                      },
                      {
                        column: 'laundry',
                        type: Number,
                        value: data => data.laundry
                      },
                      {
                        column: 'checkIn',
                        type: Number,
                        value: data => data.checkIn
                      },
                      {
                        column: 'checkOut',
                        type: Number,
                        value: data => data.checkOut
                      },
                      {
                        column: 'service',
                        type: Number,
                        value: data => data.service
                      }
                    ]
                  }).then(blob => {
                    saveAs(blob, `${props.csvName.toString()}.xlsx`);
                  });
                }}
              >
                {`${props.csvName.toString()}.xlsx`}
                {/* {intl.get('expenses.headerLabels.template').d('Download Template Link')} */}
              </Button>
              {/* </CSVLink> */}
            </p>
            <p>
              1. Download the record template and fill in the data. <br />
              2. Export/Save the data as .xlsx file. <br />
              3. Click the area below to select file or drag file into area below to import data. <br />
              4. Click Upload to save the data into system. <br />
            </p>
          </div>
        }
        type="info"
        showIcon
        style={{
          marginBottom: 16
        }}
      />

      {validatedData.length === 0 ? (
        <Upload.Dragger beforeUpload={() => false} onChange={handleUploadedTemplate} fileList={[]} withCredentials>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support only for template upload. Strictly prohibit from uploading others data or other band files</p>
        </Upload.Dragger>
      ) : (
        <Table
          bordered
          size="small"
          rowKey={'id'}
          scroll={{
            x: true
          }}
          columns={columns}
          dataSource={validatedData}
          loading={loading}
        />
      )}
    </Modal>
  );
};

export default BulkExpensesUploadModal;
